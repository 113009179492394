import { Footer } from '@cfra-nextgen-frontend/shared/src/components/Footer';
import { SearchSyntaxInfo } from '@cfra-nextgen-frontend/shared/src/components/InformationPopup/SearchSyntaxInfo';
import { ItemVariant4 } from '@cfra-nextgen-frontend/shared/src/components/ItemComponents/ItemVariant4';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { PageWithComponentInHeader } from '@cfra-nextgen-frontend/shared/src/components/PageLayouts/PageWithComponentInHeader';
import { FiltersModalContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ResultsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { ChipItem } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/types';
import { UseFiltersFormInputProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/hooks/useFiltersForm';
import { SearchInputVariant2 } from '@cfra-nextgen-frontend/shared/src/components/SearchInput/SearchInputVariant2';
import { getOptionsContainer } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/optionsContainer';
import { TitleDividerSubtitle } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/TitleWithSubtitle';
import { ShowHideStrategies, TypeSearch } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/TypeSearch';
import { getFiltersReqBody, SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { getDividerString } from '@cfra-nextgen-frontend/shared/src/utils/strings';
import { Box, createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { determineGetCompanySuggesterOptions } from 'features/topNavigation/companySuggester';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { researchFiltersRequestParams } from 'utils/api';
import {
    horizontalPaddingInPx,
    horizontalPaddingInSu,
    maxPageWidthInPx,
    maxPageWidthInPxIncludingPadding,
} from 'utils/lookAndFeel';
import { pageWithComponentInHeaderStyleProps } from 'utils/styles';
import { FiltersSection } from './FiltersSection';
import { SearchResults } from './SearchResults';
import { SortOptions } from 'utils/enums';

export const TemporaryPlaceholder = () => (
    <div style={{ width: '100%', height: '700px', backgroundColor: '#FFFF', borderRadius: '10px' }}></div>
);

const divider = getDividerString('divider');
const companyFilterName = 'insights.research_report_security__company.research_report_security.company.cfra_company_id';
const searchFilterName = 'search';

type ResearchComponentProps = {
    filtersSectionProps: { getFiltersJsx: UseFiltersFormInputProps['getFiltersJsx'] };
    sideBarComponent?: React.ReactNode;
    queriesKeyFirstElementPostfix?: string;
    screenerSearchByParams?: SearchByParams;
    permanentPostData?: Record<string, any>;
    showTypeSearchComponent?: boolean;
    showOnlyTableView?: boolean;
    showTopLevelSortOptions?: boolean;
    defaultFilters?: Record<string, any>;
    defaultSortOptions?: SortOptions | undefined;
};

export function ResearchComponent({
    filtersSectionProps,
    sideBarComponent,
    queriesKeyFirstElementPostfix,
    screenerSearchByParams,
    showTypeSearchComponent,
    showOnlyTableView,
    showTopLevelSortOptions,
    defaultFilters,
    defaultSortOptions
}: ResearchComponentProps) {
    const [externalPostData, setExternalPostData] = useState<Record<string, any>>();
    const [externalChipItems, setExternalChipItems] = useState<Record<string, ChipItem>>();
    const [searchTerms, setSearchTerms] = useState<Array<string>>([]);

    const onCompanyClick = (companyId: string, ticker: string, exchangeCode: string) => {
        setExternalPostData((previousValue: Record<string, any>) => {
            return {
                ...previousValue,
                [companyFilterName]: {
                    values: [...(previousValue?.[companyFilterName]?.values || []), companyId],
                },
            };
        });

        const chipId = `${companyFilterName}${divider}${companyId}`;

        const chipItems: Record<string, ChipItem> = {
            [chipId]: {
                chip: {
                    label: `${ticker}:${exchangeCode}`,
                    values: '',
                },
                stateData: {
                    controlID: chipId,
                    values: [],
                    filterSections: {},
                },
            },
        };

        setExternalChipItems((previousValue) => ({
            ...previousValue,
            ...chipItems,
        }));
    };

    const onSearchTermClick = useCallback((searchTerm: string) => {
        const chipId = `${searchFilterName}${divider}${searchTerm}`;

        const chipItems: Record<string, ChipItem> = {
            [chipId]: {
                chip: {
                    label: 'Search Term',
                    values: searchTerm,
                },
                stateData: {
                    controlID: chipId,
                    values: [],
                    filterSections: {},
                },
            },
        };

        setExternalChipItems((previousValue) => ({
            ...previousValue,
            ...chipItems,
        }));

        setSearchTerms((previousValue) => [...previousValue, searchTerm]);
    }, []);

    const clearAllExternalChipItems = useCallback(() => {
        setExternalChipItems(undefined);
        setExternalPostData(undefined);
        setSearchTerms([]);
    }, []);

    const onExternalChipDeleteClick = useCallback((key: string) => {
        const filterAndValue = key.split(divider);

        if (filterAndValue.length !== 2) {
            throw new Error('onExternalChipDeleteClick exception. Invalid chip key');
        }

        const [filter, value] = filterAndValue;

        setExternalChipItems((previousValue) => {
            previousValue && delete previousValue[key];
            return { ...previousValue };
        });

        switch (filter) {
            case companyFilterName:
                setExternalPostData((previousValue: Record<string, any>) => {
                    const newValue: Record<string, any> = {
                        ...previousValue,
                        [filter]: {
                            values: [
                                ...(previousValue?.[filter]?.values || []).filter((_value: string) => _value !== value),
                            ],
                        },
                    };

                    if (newValue[filter].values.length === 0 && newValue[filter]) {
                        delete newValue[filter];
                    }

                    return newValue;
                });
                break;
            case searchFilterName:
                setSearchTerms((previousValue) => previousValue.filter((_value: string) => _value !== value));
                break;
            default:
                break;
        }
    }, []);

    const leftColumnWidthInSu = 8.84;

    const { state } = useLocation();
    const externalSearchTerm = state?.searchTerm;

    useEffect(() => {
        if (typeof externalSearchTerm === 'string' && externalSearchTerm.length > 0) {
            onSearchTermClick(externalSearchTerm);
        }
    }, [externalSearchTerm, onSearchTermClick]);

    const filtersRequestParams: Parameters<typeof FiltersSection>[0]['filtersRequestParams'] = useMemo(() => {
        return [
            {
                ...researchFiltersRequestParams[0],
                requestBody: getFiltersReqBody(defaultFilters),
            },
            researchFiltersRequestParams[1],
        ];
    }, [defaultFilters]);

    return (
        <ResultsContextProvider onChipClearAllExternalCallback={clearAllExternalChipItems}>
            <FiltersModalContextProvider>
                <>
                    <PageWithComponentInHeader
                        {...pageWithComponentInHeaderStyleProps}
                        component={showTypeSearchComponent &&
                            <Box
                                sx={{
                                    maxWidth: maxPageWidthInPx,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    paddingTop: '23px',
                                    paddingBottom: '23px',
                                }}>
                                <ThemeProvider theme={createTheme()}>
                                    <TypeSearch
                                        showHideStrategy={ShowHideStrategies.UseShowResultsContainer}
                                        showSearchInput
                                        outerOnInputKeyDownCallback={(event) => {
                                            if (event.key !== 'Enter') {
                                                return;
                                            }

                                            const value = (event.target as HTMLInputElement).value;
                                            onSearchTermClick(value);
                                        }}
                                        getLeftOptions={determineGetCompanySuggesterOptions({
                                            size: 25,
                                            externalOnClickCallback: (data) => {
                                                onCompanyClick(
                                                    data?._source?.['company_security.company.cfra_company_id'],
                                                    data?._source?.['company_security.security_trading.ticker_symbol'],
                                                    data?._source?.[
                                                        'company_security.security_trading.exchange_lid.exchange_code'
                                                    ],
                                                );
                                            },
                                            passNavigateUrl: false,
                                            getOptionsContainer,
                                            queryKeyFirstElementPostfix: queriesKeyFirstElementPostfix,
                                        })}
                                        getRightOptions={({
                                            inputValue,
                                            onLinkClickCallback,
                                            titleWithScrollableAreaProps,
                                        }) => {
                                            const OptionsContainer = getOptionsContainer(titleWithScrollableAreaProps);

                                            return (
                                                <OptionsContainer key='Text search search title and search term component'>
                                                    <ItemVariant4
                                                        onClick={() => {
                                                            onSearchTermClick(inputValue);
                                                            onLinkClickCallback?.();
                                                        }}
                                                        value={inputValue}
                                                    />
                                                </OptionsContainer>
                                            );
                                        }}
                                        SearchInputComponent={SearchInputVariant2}
                                        searchInputComponentProps={{
                                            afterInputSlot: (
                                                <Box
                                                    sx={{
                                                        paddingLeft: '8px',
                                                    }}>
                                                    <SearchSyntaxInfo buttonFontSize={21} />
                                                </Box>
                                            ),
                                        }}
                                        offset={[-240.5, 19]}
                                        contextBoxStyles={{
                                            maxHeight: 'calc(95vh - 163px)',
                                        }}
                                        titles={{
                                            left: 'Companies',
                                        }}
                                        titleNodes={{
                                            right: (
                                                <TitleDividerSubtitle
                                                    title='Text Search'
                                                    subTitle='Select text term to add to your query'
                                                />
                                            ),
                                        }}
                                    />
                                </ThemeProvider>
                            </Box>
                        }
                        outletComponent={
                            <Box
                                sx={{
                                    width: '100%',
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                <FiltersSection
                                    externalChipItems={externalChipItems}
                                    onExternalChipDeleteClick={onExternalChipDeleteClick}
                                    filtersRequestParams={filtersRequestParams}
                                    {...filtersSectionProps}
                                />
                                <Grid
                                    container
                                    spacing={sideBarComponent ? horizontalPaddingInSu : undefined}
                                    sx={{
                                        maxWidth: sideBarComponent
                                            ? `calc(${maxPageWidthInPx} + ${horizontalPaddingInPx * 3}px)`
                                            : maxPageWidthInPxIncludingPadding,
                                        paddingLeft: horizontalPaddingInSu,
                                        paddingRight: horizontalPaddingInSu,
                                    }}>
                                    <Grid item xs={sideBarComponent ? leftColumnWidthInSu : 12}>
                                        <SearchResults
                                            searchTerm={searchTerms.join(' OR ')}
                                            externalPostData={externalPostData}
                                            defaultFilters={defaultFilters}
                                            defaultSortOptions={defaultSortOptions}
                                            queryKeyFirstElementPostfix={queriesKeyFirstElementPostfix}
                                            externalSearchByParams={screenerSearchByParams}
                                            showOnlyTableView={showOnlyTableView}
                                            showTopLevelSortOptions={showTopLevelSortOptions}
                                            gridViewItemContainerSx={sideBarComponent ? {} : { width: '305px' }}
                                        />
                                    </Grid>
                                    {sideBarComponent && (
                                        <Grid item xs={12 - leftColumnWidthInSu}>
                                            {sideBarComponent}
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                        }
                    />
                    <Footer />
                </>
            </FiltersModalContextProvider>
        </ResultsContextProvider>
    );
}
